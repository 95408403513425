/* eslint-disable no-underscore-dangle */
import { GLOBAL, afterDomContentLoaded } from 'saddlebag-browser';
import logger from 'saddlebag-logger';
import observer from 'saddlebag-observer';
import { FunnelEventsTracking as FunnelEventLogger } from 'saddlebag-user-tracking-events';

import './public-path';
import './home.scss';
import { namespace, ACTION_TYPE, LOAD_STATUS } from './constants';
import FunnelEventsListener from './funnelEventsListener';
import logMiniEventHelper from './mini-event/logMiniEventHelper';
import { loadExternalScripts } from './scriptLoader';
import { setCarHireSeoEventValue } from './sessionStorage';
import '@skyscanner/backpack-web/bpk-component-horizontal-nav/src/BpkHorizontalNav.module.css';
import '@skyscanner/backpack-web/bpk-component-horizontal-nav/src/BpkHorizontalNavItem.module.css';
import { getAcceptLanguageFirstLang, parsePageType } from './utils';

const $window = GLOBAL.getWindow();
const clientSideContext = $window.__internal;
const rootComponent = `${namespace}.root`;

const loadSearchControls = async () => {
  const { default: hydrateSearchControls } = await import(
    /* webpackMode: "eager" */ './search-controls'
  );
  hydrateSearchControls();
};

const afterPageLoaded = async (data) => {
  const { enableGoogleCWVPOC, pageType } = data;

  const { default: hydrate } = await import('./hydrate');
  hydrate();

  observer.publish('grappler-track', {
    eventName: 'PageLoaded',
    fullSchemaName: 'landing_pages.PageLoaded',
    message: {
      microsite: 'FALCON',
      page_type: pageType,
      accept_language: (data.culture && data.culture.acceptLanguage) || '',
      accept_language_first_lang:
        getAcceptLanguageFirstLang(
          (data.culture && data.culture.acceptLanguage) || null,
        ) || '',
      suggested_market: (data.culture && data.culture.suggestedMarket) || '',
    },
    isMiniEvent: true,
    consentRequired: true,
    microsite: {
      name: namespace,
    },
  });

  const { name, variant } = data.distilledExperiment;
  if (name && variant) {
    observer.publish('tracking-event', {
      ga: [
        {
          category: `${data.pageType}-results-distilled-experiment`,
          action: variant,
          label: `${name} Results Variant ${variant.toUpperCase()}`,
        },
      ],
    });
  }

  await Promise.all([
    import(/* webpackMode: "eager" */ './header').then(
      ({ default: hydrateHeader }) => hydrateHeader(),
    ),
    import(/* webpackMode: "eager" */ './tag-manager').then(
      ({ default: tagManager }) => tagManager(),
    ),

    import(/* webpackMode: "eager" */ './strevda-runtime').then(
      ({ default: strevdaRuntime }) => strevdaRuntime(),
    ),

    import(/* webpackMode: "eager" */ './ssculture').then(
      ({ default: setCultureCookie }) => setCultureCookie(),
    ),

    import(/* webpackMode: "eager" */ './gtmClient').then(
      ({ default: loadGtmClient }) => loadGtmClient(),
    ),
  ]);

  const shouldSetupPostPageLoadLayoutUpdates =
    !!$window.document.getElementById('most_wanted_container_side');
  if (shouldSetupPostPageLoadLayoutUpdates) {
    const { default: setupPostPageLoadLayoutUpdates } = await import(
      /* webpackMode: "lazy" */ './update-layout'
    );
    setupPostPageLoadLayoutUpdates();
  }

  if (enableGoogleCWVPOC) {
    const { default: googleCWVPOC } = await import(
      /* webpackMode: "lazy" */ './google-cwv-poc'
    );
    googleCWVPOC($window);
  }

  const { default: customNewRelicAttributes } = await import(
    /* webpackMode: "eager" */ './custom-new-relic-attributes'
  );
  customNewRelicAttributes($window);
};

(async () => {
  try {
    await loadExternalScripts();
  } catch (missing) {
    logger.logError(missing, {
      component: rootComponent,
    });
  }

  try {
    const funnelEventsLogger = new FunnelEventLogger(
      process.env.NODE_ENV === 'production' ? 'public' : 'public-sandbox',
      namespace,
    );
    const funnelEventsListener = new FunnelEventsListener(
      funnelEventsLogger,
      observer,
    );
    funnelEventsListener.subscribeToFunnelEvents();

    await loadSearchControls();
    afterDomContentLoaded(() =>
      afterPageLoaded(clientSideContext).catch((err) => {
        logger.logError(err, {
          component: rootComponent,
        });
      }),
    );
    logMiniEventHelper({
      action_type: ACTION_TYPE.PAGE_LOADED,
      load_status: LOAD_STATUS.LOADED,
    });
    // Write source_page.page_type to session storage after page was loaded.
    // This page_type is used on dayView page to log a Mini-Event,
    // that help analyzing the enrichment of Landing page components.
    // https://confluence.skyscannertools.net/display/CHS/Conversion+Rate+%3A+From+Landing+Page+To+Search+Result+Page
    const pageType = parsePageType(clientSideContext.pageType);
    setCarHireSeoEventValue('page_type', pageType, 'source_page');
  } catch (err) {
    logger.logError(err, {
      component: rootComponent,
    });
  }
})();
