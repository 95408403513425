/* eslint-disable no-underscore-dangle */
import { GLOBAL } from 'saddlebag-browser';
import { LocalStorage } from 'saddlebag-localstorage';

import { CAR_HIRE_SEO_EVENT_PAGE_TYPE } from './constants';
import { parsePageType } from './utils';

const $window = GLOBAL.getWindow();
const { pageType } = $window.__internal || {};

const namespacedStorages: Record<string, typeof LocalStorage> = {};

const getStorage = (namespace: string) => {
  namespacedStorages[namespace] =
    namespacedStorages[namespace] || new LocalStorage(namespace);
  return namespacedStorages[namespace];
};

// eslint-disable-next-line import/no-unused-modules
export const getValue = (
  key: string,
  defaultValue: unknown,
  namespace: string,
  useCookie: boolean = false,
) => getStorage(namespace).tryGetValue(key, useCookie, true) || defaultValue;

export const setValue = (
  key: string,
  value: unknown,
  namespace: string,
  useCookie: boolean = false,
) => {
  getStorage(namespace).trySetValue(key, value, useCookie, true);
};

export const setCarHireSeoEventValue = (
  key: string,
  value: unknown,
  namespace: string,
  useCookie: boolean = false,
) => {
  if (CAR_HIRE_SEO_EVENT_PAGE_TYPE.includes(parsePageType(pageType))) {
    setValue(key, value, namespace, useCookie);
  }
};
