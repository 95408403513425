import observer from 'saddlebag-observer';

import { namespace } from './constants';

export default function logMiniEvent(
  eventName: string,
  schemaName: string,
  message: any,
): void {
  observer.publish('grappler-track', {
    eventName,
    fullSchemaName: `${schemaName}.${eventName}`,
    message,
    isMiniEvent: true,
    consentRequired: true,
    microsite: {
      name: namespace,
    },
  });
}
