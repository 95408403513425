/* eslint-disable no-underscore-dangle */
import { GLOBAL } from 'saddlebag-browser';
import registry from 'saddlebag-componentregistry';
import logger from 'saddlebag-logger';
import observer from 'saddlebag-observer';

const $window = GLOBAL.getWindow();

const {
  isDefaultLocaleForMarket,
  pageType,
  pageVariant,
  searchControlsConfig,
  searchControlsParams,
  searchControlsVersion,
} = $window.__internal;

export default () => {
  observer.subscribe('flights-searchcontrols-loaded', () => {
    const properties = {
      searchControlsVersion,
      pageType,
      pageVariant,
    };

    logger.logOperationalEvent({
      eventName: 'flights-search-controls-loaded',
      isDefaultLocaleForMarket: `${isDefaultLocaleForMarket}`,
      ...properties,
    });
  });

  observer.subscribe('ga-track-event-with-page-name', (data: any) => {
    const properties = {
      searchControlsVersion,
      pageType,
      isDefaultLocaleForMarket: `${isDefaultLocaleForMarket}`,
      pageVariant,
    };

    if (data && data.action === 'SearchClick') {
      logger.logOperationalEvent({
        eventName: 'flights-search-controls-clicked',
        ...properties,
      });
    }
  });

  registry.awaitComponent(
    {
      name: 'flights-search-controls',
      version: searchControlsVersion,
      params: {
        locale: searchControlsParams.locale,
        market: searchControlsParams.market,
        scaffolding: false,
        useComponentRegistry: true,
        oneLine: true,
      },
    },
    ({ hydrate, props }: any) =>
      hydrate(props, searchControlsParams, searchControlsConfig),
  );
};
