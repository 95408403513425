/* eslint-disable no-underscore-dangle */
import { GLOBAL } from 'saddlebag-browser';

import { FALCON_EVENT, MINI_EVENT_PAGE_TYPE } from '../constants';
import logMiniEvent from '../logMiniEvent';
import { parsePageType } from '../utils';

const $window = GLOBAL.getWindow();
const { culture, deviceType, pageType, pageVariant } = $window.__internal || {};

// It is common function for all verticals to log a mini-event for load/click tracking into falcon_landing_pages.proto schema
// https://confluence.skyscannertools.net/pages/viewpage.action?pageId=831688206
const logMiniEventHelper = (info: {
  [key: string]: object | string | number | boolean | undefined | null;
}) => {
  // Please do not remove the pageType control logic
  // In case if there is a page type or a future page type don’t covered in schema when emits mini events
  // It will get an error response.
  // https://github.skyscannertools.net/data-management-services/schemas/blob/master/landing_pages.proto#L19-L41
  if (MINI_EVENT_PAGE_TYPE.includes(parsePageType(pageType))) {
    const message = {
      ...info,
      common_properties: {
        page_type: parsePageType(pageType),
        page_variant: pageVariant,
        platform: deviceType,
        culture_settings: {
          country: culture?.market,
          currency: culture?.currency,
          locale: culture?.locale,
        },
      },
    };
    logMiniEvent(FALCON_EVENT.EVENT_NAME, FALCON_EVENT.SCHEMA_NAME, message);
  }
};

export default logMiniEventHelper;
